<template>
  <v-container
    slot="content"
    grid-list-lg
    class="case-note-create">
    <a
      class="back-link"
      @click="$router.go(-1)">
      voltar para anotações <v-icon small>{{ $root.icons.action.previous }}</v-icon>
    </a>
    <v-form
      ref="form"
      v-model="valid"
      class="mt-6"
      lazy-validation>
      <v-layout
        row
        class="mt-3">
        <v-flex
          xs12
          sm12>
          <wysiwyg
            v-model="note"
            placeholder="Digite sua nota..." />
        </v-flex>
      </v-layout>
      <div class="actions-message">
        <v-btn
          small
          text
          color="darkGray"
          @click="$router.go(-1)">
          Cancelar
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mr-0"
          @click="createNote">
          Salvar
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import Modal from '@/components/ui/Modal'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'

  export default {
    components: { Modal },
    mixins: [ validate, masks ],
    data () {
      return {
        valid: true,
        note: ''
      }
    },
    methods: {
      createNote () {
        if (this.$refs.form.validate()) {
          const id = this.$uuid.v4()
          const note = this.note

          this.$store.dispatch('cases/createNote', { id, note }).then(() => {
            this.$router.go(-1)
          })
        }
      }
    }
  }
</script>

<style lang="sass">
  .case-note-create

    .validation-absolute

      .v-input-group__details
        position: relative
        overflow: initial

      .v-input-group__messages
        position: absolute
        width: 400%
        left: 0
        top: 2px

    .v-input-group label
      font-size: 14px

    .editr
      .editr--toolbar
        display: none

      .editr--content
        font-size: 13px
        padding: 12px 15px
        min-height: auto

        p
          margin: 0
          padding: 0

    .actions-message
      text-align: right
      padding-top: 30px

    .ql-toolbar.ql-snow
      padding: 8px 6px

      .ql-formats
        margin-right: 10px

  @media screen and (max-width: $max-mobile)
    .case-note-create
      .actions-message
        padding-top: 60px

</style>
