<template>
  <loading
    v-if="loading > 0"
    class="absolute-center" />
  <v-container
    v-else
    grid-list-lg
    class="pt-0 pr-0 case-notes">
    <perfect-scrollbar :options="options">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="notes.length > 0"
            color="primary"
            class="btn-fixed-bottom mr-2 mb-2"
            fixed
            dark
            fab
            small
            v-on="on"
            @click="createNote">
            <v-icon>{{ $root.icons.action.add }}</v-icon>
          </v-btn>
        </template>
        <span>Criar uma Nota</span>
      </v-tooltip>
      <v-layout
        row
        wrap>
        <v-flex
          xs12
          sm12
          class="py-0 pr-6">
          <v-timeline
            v-if="notes.length > 0"
            align-top
            dense>
            <v-timeline-item
              v-for="item in notes"
              :key="item.id"
              icon="mdi-note-text">
              <v-card class="elevation-1">
                <v-card-title class="body-2">
                  {{ item.insertedAt }}
                </v-card-title>
                <v-card-text v-html="item.note" />
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <empty-state
            v-else
            size="75px"
            :icon="$root.icons.link.notes"
            class="absolute-center">
            Não há anotações <br>
            <v-btn
              v-if="showNotesCreateButton"
              color="accent"
              class="mt-2"
              depressed
              small
              @click="createNote">
              Criar uma nota
            </v-btn>
          </empty-state>
        </v-flex>
      </v-layout>
      <router-view />
    </perfect-scrollbar>
  </v-container>
</template>

<script>
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ casesMixin ],
    data () {
      return {
        headers: [
          { text: 'Data', value: 'insertedAt', sortable: false, align: 'left' },
          { text: 'Mensagem', value: 'note', sortable: false, align: 'center' }
        ],
        options: { suppressScrollX: true, wheelPropagation: false }
      }
    },
    methods: {
      createNote () {
        this.$router.push({ name: 'CaseNoteCreate', params: { caseId: this.$route.params.caseId } })
      }
    },
    computed: {
      showNotesCreateButton () {
        const canCreate = this.$can('CaseNotesCreate')
        const caseState = this.case && this.case.state
        return canCreate && !['accepted', 'refused'].includes(caseState)
      },
      ...mapGetters({
        notes: 'cases/notes',
        loading: 'cases/notesLoading'
      })
    },
    beforeMount () {
      this.$store.dispatch('cases/getNotes', this.$route.params.caseId)
    }
  }
</script>

<style lang="sass">
  .btn-fixed-bottom
    position: fixed
    bottom: 0
    right: 0

  .ellipsis
    max-width: 200px
    margin-bottom: 0

  .case-notes
    height: 100%
    transform: translateX(0)

    .ps
      height: calc(100vh - 100px)

  .v-timeline--dense
    &::before
      left: 20px !important

    .v-timeline-item__body
      max-width: calc(100% - 20px)
      margin-left: 20px

    .v-timeline-item__divider
      min-width: 40px
</style>
